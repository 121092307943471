
























































import {Component, Prop, Ref, Vue, Watch} from "vue-property-decorator";
import {quillEditor} from 'vue-quill-editor';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import vSelect from 'vue-select';

@Component({
    components: {
        quillEditor,
        vSelect,
        VuePerfectScrollbar
    }
})
export default class EmailCompose extends Vue {
    @Prop() to!: string;
    @Ref('mailToElement') mailToElement!: any;
    isShow = false;
    mailTo = '';
    mailSubject = '';
    mailCC = [];
    mailBCC = [];
    mailMessage = '';
    errors = [];
    editorOption = {
        modules: {
            toolbar: [
                ['bold', 'italic', 'underline', 'strike', 'link', 'blockquote', 'code-block'],
                [{'header': 1}, {'header': 2}],
                [{'list': 'ordered'}, {'list': 'bullet'}],
                [{'font': []}],
            ],
        },
        placeholder: 'Message'
    };
    settings = {
        maxScrollbarLength: 60,
        wheelSpeed: 0.30,
    };

    @Watch('isShow')
    onShown(value: boolean) {
        if (value) {
            setTimeout(() => {
                this.mailToElement.$el.querySelector('input').focus();
            }, 100);
        }
    }

    show() {
        this.isShow = true;
        this.mailTo = this.to;
    }

    clearFields() {
        this.$nextTick(() => {
            this.mailTo = '';
            this.mailSubject = '';
            this.mailCC = [];
            this.mailBCC = [];
            this.mailMessage = '';
        });
    }

    sendMail() {
        this.clearFields()
    }
}
